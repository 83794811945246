import { createSelector } from '@reduxjs/toolkit';

import { CONFIRMATION_FORM_FEATURE_KEY, ConfirmationFormState } from './';

export const getConfirmationFormState = (
  rootState: any,
): ConfirmationFormState => rootState[CONFIRMATION_FORM_FEATURE_KEY];

export const selectFieldConfigurations = createSelector(
  getConfirmationFormState,
  (s) => s.fieldConfigurations,
);

export const selectConfirmationData = createSelector(
  getConfirmationFormState,
  (s) => s.confirmationData,
);

export const selectIsConfirmationFormLoading = createSelector(
  getConfirmationFormState,
  (s) => s.isConfirmationFormLoading,
);
