import { createSelector } from '@reduxjs/toolkit';

import { PAYMENT_PROCESSING_FEATURE_KEY, PaymentProcessingState } from './';

export const getProcessingState = (rootState: any): PaymentProcessingState =>
  rootState[PAYMENT_PROCESSING_FEATURE_KEY];

export const selectProcessingLoading = createSelector(
  getProcessingState,
  (s) => s.isProcessingLoading,
);

export const selectProcessingResponse = createSelector(
  getProcessingState,
  (s) => s.processingResponse,
);

/* @TODO: map response to single state parameter */
export const selectProcessingOutput = createSelector(
  getProcessingState,
  (s) =>
    s.processingResponse?.redirectOutput || s.processingResponse?.resultAction,
);

export const selectTransactionId = createSelector(
  getProcessingState,
  (s) => s.processingResponse?.transactionId,
);
