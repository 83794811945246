import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  ResultContainerTypes,
  selectProcessingOutput,
  selectProcessingResultSectionActivated,
  selectRegistrationResponse,
} from '../slices';

export const useIframeContentController = (): {
  showContent: boolean;
  hideContentOnIframeRender: boolean;
} => {
  const processingOutput = useSelector(selectProcessingOutput);
  const registrationResponse = useSelector(selectRegistrationResponse);
  const isProcessingResultActivated = useSelector(
    selectProcessingResultSectionActivated,
  );

  const showContent = useMemo(() => {
    return !(
      (processingOutput?.container === ResultContainerTypes.IFRAME ||
        processingOutput?.container === ResultContainerTypes.WINDOW ||
        registrationResponse?.resultAction?.container ===
          ResultContainerTypes.IFRAME ||
        registrationResponse?.resultAction?.container ===
          ResultContainerTypes.WINDOW ||
        (registrationResponse?.resultAction?.container ===
          ResultContainerTypes.PARAMETERS &&
          Boolean(
            Object.values(registrationResponse?.resultAction?.parameters)
              .length,
          )) ||
        (processingOutput?.container === ResultContainerTypes.PARAMETERS &&
          Boolean(Object.values(processingOutput.parameters).length))) &&
      isProcessingResultActivated
    );
  }, [processingOutput, isProcessingResultActivated, registrationResponse]);

  const hideContentOnIframeRender = useMemo(() => {
    return (
      (processingOutput?.container === ResultContainerTypes.IFRAME ||
        registrationResponse?.resultAction?.container ===
          ResultContainerTypes.IFRAME) &&
      isProcessingResultActivated
    );
  }, [registrationResponse, processingOutput, isProcessingResultActivated]);

  return { showContent, hideContentOnIframeRender };
};
