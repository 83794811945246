import { IntegrationSource, TransactionType } from '../../interfaces';
import { PaymentMethodInput } from '../paymentForm';

export interface FetchPaymentMethodsInput {
  merchantId: string;
  userId: string;
  sessionId: string;
  type: TransactionType;
}

export interface FieldInterface extends PaymentMethodInput {
  componentName: string;
}

export interface FieldsByTypeInterface extends FieldInterface {
  cvv?: FieldInterface;
  expirationDate?: FieldInterface;
}

export interface Preprocessor {
  type: string;
  parameter: string;
}

export interface PaymentMethodShort {
  id: string;
  name: string;
  categories: string[];
  recommended: boolean;
  logo: string;
  order?: number;
}

export interface Category {
  id: string;
  name: string;
  label: string;
}

export interface CategoryAggregate {
  name: string;
  label: string;
  paymentMethods: PaymentMethodShort[];
}

export const RewardTypes = {
  Fractional: 'fractional',
  Fixed: 'fixed',
  UnCountable: 'unCountable',
} as const;

type RewardTypeKeys = (typeof RewardTypes)[keyof typeof RewardTypes];

export interface AmountInterval {
  rewardType: RewardTypeKeys;
  reward: {
    from: number;
    to: number;
  };
  attributes: {
    measurement: string;
    volume?: number;
    fraction?: number;
  };
  description: {
    params: {
      formula?: string;
    };
    pattern: string;
  };
  title: {
    params: {
      formula?: string;
    };
    pattern: string;
  };
}

interface CashierConfiguration {
  displayBonusCalculator: boolean;
  displayBonusFastSums: boolean;
  iconURL: string;
}

export interface PaymentMethodsState {
  arePaymentMethodsLoading: boolean;
  isPaymentMethodSelectedManually: boolean;
  loadCertainPaymentMethod: boolean;
  categoryMap: { [id: string]: CategoryAggregate };
  paymentMethod: PaymentMethod;
  isCategoryFilterActivated: boolean;
  activeCategory: string;
  country: string;
  paymentIqMerchantId: string;
  registrationRequired: boolean;
  tax: {
    [key: string]: TaxConfiguration;
  };
  bonus: {
    amountIntervals: AmountInterval[];
    cashierConfiguration: CashierConfiguration;
    id: string;
  };
}

export interface TaxConfiguration {
  taxPercentage: string;
  deductibleStake: string;
}

export interface PaymentMethodRules {
  min: number;
  max: number;
  recommended: boolean;
  amounts: string[];
}

export type NextStepChannelTypes = 'display' | 'html' | 'form' | 'redirect';
export type ContainerChannelType = 'window' | 'iframe';

export interface NextStepChannel {
  nullable: boolean;
  types: NextStepChannelTypes[];
  containers: ContainerChannelType[];
}

export interface NextStepInfo {
  post_process: null | NextStepChannel;
  post_confirm: null | NextStepChannel;
  channel: null | NextStepChannel;
}

export interface PaymentMethod {
  id: string;
  name: string;
  vendor: string;
  referenceId: string;
  inputs: PaymentMethodInput[];
  activeInputs: PaymentMethodInput[];
  account_form_inputs: PaymentMethodInput[];
  img?: string;
  categories: Category[];
  default_amounts: number[];
  ruleParams?: PaymentMethodRules;
  order?: number;
  description?: string;
  final_screen_description?: string;
  display_form_description?: string;
  multistep?: boolean;
  category_order?: string;
  source?: IntegrationSource;
  show_account_form?: boolean;
  preprocessor?: Preprocessor;
  logo: string;
  hideAmount: boolean;
  next_step_info: NextStepInfo;
}
