import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  PaymentRegistrationRequest,
  PaymentRegistrationResponse,
  registerPaymentStart,
  registrationPaymentFailure,
  registrationPaymentSuccess,
} from '../../slices';

export const paymentRegistrationEpic = (action$, state$, { Api }) =>
  action$.pipe(
    ofType(registerPaymentStart.type),
    switchMap((action: { payload: PaymentRegistrationRequest }) =>
      Api.registerPayment(action.payload).pipe(
        map((data: PaymentRegistrationResponse) =>
          registrationPaymentSuccess(data),
        ),
        catchError((err) => of(registrationPaymentFailure(err))),
      ),
    ),
  );
