import { useSelector } from 'react-redux';

import { CASHIER_CONFIGURATION } from '../constants';
import { selectPaymentMethod } from '../slices';

type PaymentMethodParameter = keyof typeof CASHIER_CONFIGURATION;

interface UseCashierConfigurator {
  isPaymentMethodIncluded: (key: PaymentMethodParameter) => boolean;
  isPaymentMethodStartedWith: (key: PaymentMethodParameter) => boolean;
}

export const usePaymentMethodConfigurator = (configuration: {
  [key in PaymentMethodParameter]: string[];
}): UseCashierConfigurator => {
  const { id: paymentMethodId } = useSelector(selectPaymentMethod);

  const isPaymentMethodIncluded = (key: PaymentMethodParameter): boolean => {
    return configuration[key].includes(paymentMethodId);
  };

  const isPaymentMethodStartedWith = (key: PaymentMethodParameter): boolean => {
    return configuration[key].some((value) =>
      paymentMethodId.startsWith(value),
    );
  };

  return {
    isPaymentMethodIncluded,
    isPaymentMethodStartedWith,
  };
};
