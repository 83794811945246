import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  Category,
  getCertainPaymentMethodFailure,
  getCertainPaymentMethodStart,
  getCertainPaymentMethodSuccess,
  getPaymentMethodsFailure,
  getPaymentMethodsStart,
  getPaymentMethodsSuccess,
  inputIconsMap,
  inputMasksMap,
  IPaymentMethod,
  PaymentMethodShort,
} from '../../slices';

const processMethodInputs = (inputs: any[]) => {
  if (!inputs) return;

  // eslint-disable-next-line consistent-return
  return inputs.reduce((acc, curr) => {
    acc.push({
      ...curr,
      icon: inputIconsMap[curr.type],
      mask: inputMasksMap[curr.type],
    });

    return acc;
  }, []);
};

export const fetchPaymentMethodsEpic = (action$, state$, { Api }) =>
  action$.pipe(
    ofType(getPaymentMethodsStart.type),
    switchMap((action: { payload }) =>
      Api.fetchPaymentMethods(action.payload).pipe(
        map(
          (data: {
            payment_methods: PaymentMethodShort[];
            categories: Category[];
          }) => getPaymentMethodsSuccess(data),
        ),
        catchError(() => of(getPaymentMethodsFailure())),
      ),
    ),
  );

export const fetchCertainPaymentMethodEpic = (action$, state$, { Api }) =>
  action$.pipe(
    ofType(getCertainPaymentMethodStart.type),
    switchMap(
      (action: {
        payload: { transactionType; paymentMethodId; headers; bonusCode };
      }) =>
        Api.fetchCertainPaymentMethod(
          action.payload.transactionType,
          action.payload.paymentMethodId,
          action.payload.bonusCode,
        ).pipe(
          map((data: IPaymentMethod) => {
            return {
              ...data,
              method: {
                ...data.method,
                inputs: processMethodInputs(data.method.inputs),
              },
            };
          }),
          map((data: IPaymentMethod) => getCertainPaymentMethodSuccess(data)),
          catchError((error) => of(getCertainPaymentMethodFailure(error))),
        ),
    ),
  );
