import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const FILE_UPLOADING_FEATURE_KEY = 'fileUploading';

export interface AddDataToUploadPayload {
  file: File;
  name: string;
}

interface UploadFileSuccessPayload {
  name: string;
  link: string;
}

export interface UploadedData {
  file: File;
  name: string;
  link?: string;
}

export interface FileUploadingState {
  data: UploadedData;
}

export const initialFileUploadingState: FileUploadingState = {
  data: null,
};

export const fileUploadingSlice = createSlice({
  name: FILE_UPLOADING_FEATURE_KEY,
  initialState: initialFileUploadingState,
  reducers: {
    addDataToUpload: (state, action: PayloadAction<AddDataToUploadPayload>) => {
      const { file, name } = action.payload;

      state.data = {
        file,
        name,
        link: null,
      };
    },
    uploadFileStart: (state) => {},
    uploadFileSuccess: (
      state,
      action: PayloadAction<UploadFileSuccessPayload>,
    ) => {
      const newData = state.data;
      newData.link = action.payload.link;

      state.data = newData;
    },
    uploadFileFailure: (state, action: PayloadAction<any>) => {},
  },
});

export const fileUploadingReducer = fileUploadingSlice.reducer;

export const {
  addDataToUpload,
  uploadFileStart,
  uploadFileSuccess,
  uploadFileFailure,
} = fileUploadingSlice.actions;
