import { expireCookie, setCookie } from './cookie';

export interface QaToolkit {
  disableValidation: () => void;
  enableValidation: () => void;
}

export enum QaToolKitOptions {
  disableValidation = 'disableValidation',
}

declare global {
  interface Window {
    qaToolkit: QaToolkit;
  }
}

export const initiateQAToolkit = (): void => {
  /* eslint-disable no-console */
  console.groupCollapsed(
    'QA Toolkit: type window.qaToolkit to get all available options',
  );
  console.info(
    'window.qaToolkit.disableValidation(): Disable validation on front-end for transaction',
  );
  console.info(
    'window.qaToolkit.enableValidation(): Enable validation on front-end for transactions',
  );
  console.info('---------');
  console.info('Please do not forget to refresh pages to apply changes');
  console.groupEnd();
  /* eslint-enable no-console */

  window.qaToolkit = {
    disableValidation: () => {
      setCookie(QaToolKitOptions.disableValidation, 'true', {
        expires: expireCookie(),
      });
    },
    enableValidation: () => {
      setCookie(QaToolKitOptions.disableValidation, 'false', {
        expires: expireCookie(),
      });
    },
  };
};
