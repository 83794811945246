export interface CookieOptionProps {
  expires?: any;
  path: string;
}

export const getCookie = (name: string): string | null => {
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)',
    ),
  );
  return matches ? decodeURIComponent(matches[1]) : null;
};

export const expireCookie = (value?: number): Date => {
  const time = value || 15 * 60 * 1000 * 100000;
  return new Date(new Date().getTime() + time);
};

export const setCookie = (name: string, value: string, options = {}) => {
  const cookieOptions: CookieOptionProps = { path: '/', ...options };
  if (cookieOptions.expires && cookieOptions.expires.toUTCString) {
    cookieOptions.expires = cookieOptions.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  for (const optionKey in cookieOptions) {
    if (Object.prototype.hasOwnProperty.call(cookieOptions, optionKey)) {
      updatedCookie += `; ${optionKey}`;
      const optionValue = cookieOptions[optionKey];
      if (optionValue !== true) {
        updatedCookie += `=${optionValue}`;
      }
    }
  }

  document.cookie = updatedCookie;
};

export const deleteCookie = (name) => {
  setCookie(name, '', { 'max-age': -1 });
};
