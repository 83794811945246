import { createSelector } from '@reduxjs/toolkit';

import { ACCOUNTS_FEATURE_KEY, AccountsState } from './';

export const getAccountsState = (rootState: any): AccountsState =>
  rootState[ACCOUNTS_FEATURE_KEY];

export const selectAccounts = createSelector(
  getAccountsState,
  (s) => s.accounts,
);

export const selectActiveAccount = createSelector(
  getAccountsState,
  (s) => s.activeAccountOption,
);

export const selectAccountCreateInProgress = createSelector(
  getAccountsState,
  (s) => s.isAccountCreateInProgress,
);
