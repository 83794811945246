import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AccountOption {
  value: string;
  label: string;
}

export interface AccountsState {
  accounts: Record<string, AccountOption[]>;
  activeAccountOption: AccountOption;
  isAccountCreateInProgress: boolean;
}

export const ACCOUNTS_FEATURE_KEY = 'accounts';

export const initialAccountsState: AccountsState = {
  accounts: {},
  activeAccountOption: null,
  isAccountCreateInProgress: false,
};

export const accountsSlice = createSlice({
  name: ACCOUNTS_FEATURE_KEY,
  initialState: initialAccountsState,
  reducers: {
    initAccounts: (state, action: PayloadAction<any>) => {
      state.accounts = Object.entries(action.payload.accounts).reduce(
        (acc, [key, option]: any) => {
          acc[key] = option.sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1,
          );

          return acc;
        },
        action.payload.accounts,
      );
    },
    setActiveAccount: (state, action: PayloadAction<AccountOption>) => {
      state.activeAccountOption = action.payload;
    },
    resetActiveAccount: (state) => {
      state.activeAccountOption = initialAccountsState.activeAccountOption;
    },
    setAccountCreateInProgress: (state, action: PayloadAction<boolean>) => {
      state.isAccountCreateInProgress = action.payload;
    },
    setAccounts: (state, { payload }) => {
      state.accounts = {
        [payload.method.id]: payload.accounts,
      };
    },
  },
});

export const accountsReducer = accountsSlice.reducer;

export const {
  initAccounts,
  setActiveAccount,
  resetActiveAccount,
  setAccountCreateInProgress,
  setAccounts,
} = accountsSlice.actions;
