export enum SVGIcons {
  baseArrow = 'svg__arrow',
  ballLoader = 'svg_ball_loader',
}

export type SVGIconsType = {
  [key in keyof typeof SVGIcons]: string;
};

type ICONS = keyof typeof SVGIcons;

export type IconType = (typeof SVGIcons)[ICONS];
