import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProcessingOutput, RegisterPaymentRequest } from '../paymentProcessing';

export interface PaymentRegistrationRequest extends RegisterPaymentRequest {}

export interface PaymentRegistrationResponse {
  resultAction: ProcessingOutput;
  error: string;
}

export interface PaymentRegistrationState {
  registrationResponse: PaymentRegistrationResponse;
  isRegistrationLoading: boolean;
  isRegistrationSuccessed: boolean;
  isRegistrationFailed: boolean;
}

export const PAYMENT_REGISTRATION_FEATURE_KEY = 'payment_registration';

export const initialRegistrationProcessingState: PaymentRegistrationState = {
  registrationResponse: {
    resultAction: {
      type: null,
      html: '',
      url: '',
      method: null,
      parameters: {},
      container: '',
      height: null,
      width: null,
      output: '',
      fields: [],
    },
    error: '',
  },
  isRegistrationLoading: false,
  isRegistrationFailed: false,
  isRegistrationSuccessed: false,
};

export const paymentRegistrationSlice = createSlice({
  name: PAYMENT_REGISTRATION_FEATURE_KEY,
  initialState: initialRegistrationProcessingState,
  reducers: {
    resetRegistrationState: (state) => {
      state.registrationResponse = {
        ...initialRegistrationProcessingState.registrationResponse,
      };
      state.isRegistrationLoading = false;
      state.isRegistrationFailed = false;
      state.isRegistrationSuccessed = false;
    },
    registerPaymentStart: (
      state,
      action: PayloadAction<PaymentRegistrationRequest>,
    ) => {
      state.isRegistrationLoading = true;
    },
    registrationPaymentSuccess: (
      state,
      action: PayloadAction<PaymentRegistrationResponse>,
    ) => {
      state.registrationResponse = action.payload;
      state.isRegistrationLoading = false;
      state.isRegistrationSuccessed = true;
      state.isRegistrationFailed = false;
    },
    registrationPaymentFailure: (state, action: PayloadAction<any>) => {
      if (action.payload) {
        state.registrationResponse = action.payload.response || action.payload;
        state.isRegistrationLoading = false;
        state.isRegistrationSuccessed = false;
        state.isRegistrationFailed = true;
      }
    },
  },
});

export const paymentRegistrationReducer = paymentRegistrationSlice.reducer;

export const {
  resetRegistrationState,
  registrationPaymentSuccess,
  registrationPaymentFailure,
  registerPaymentStart,
} = paymentRegistrationSlice.actions;
