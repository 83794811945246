import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  makeConfirmRequestFailure,
  makeConfirmRequestStart,
  makeConfirmRequestSuccess,
  ProcessPaymentResponse,
} from '../../slices';

export const makeConfirmRequestEpic = (action$, state$, { Api }) =>
  action$.pipe(
    ofType(makeConfirmRequestStart.type),
    switchMap((action: { payload: { transactionId; paymentAttributes } }) =>
      Api.makeConfirmRequest(
        action.payload.transactionId,
        action.payload.paymentAttributes,
      ).pipe(
        map((data: ProcessPaymentResponse) => makeConfirmRequestSuccess(data)),
        catchError((err) => of(makeConfirmRequestFailure(err))),
      ),
    ),
  );
