import { CashierFieldPreprocessor } from '../../models';
import { Preprocessor } from '../../slices';

const appendPaymentIqCardEncrypter = (merchantId: string): void => {
  const paymentIqScript = document.getElementById('paymentIqScript');

  if (!paymentIqScript) {
    const script = document.createElement('script');
    script.id = 'paymentIqScript';
    script.type = 'text/javascript';
    script.async = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    script.src = `${(window as any).paymentIqCardEncrypterUrl}/${merchantId}`;
    document.body.appendChild(script);
  }
};

export const loadPreprocessorResources = (preprocessor: Preprocessor) => {
  if (!preprocessor) {
    return;
  }

  switch (preprocessor.type) {
    case CashierFieldPreprocessor.PIQ_ENCRYPTOR:
      appendPaymentIqCardEncrypter(preprocessor.parameter);
      break;
  }
};
