import { ContainerChannelType, NextStepChannelTypes } from '../slices';

const openedWindow: { [key: string]: WindowProxy } = {};

export const generateLoadingScreenUrl = ({
  platform,
  direction,
}: {
  platform: string;
  direction: string;
}): string => {
  return `/loading?platform=${platform}&direction=${direction}`;
};

export const validateRedirectStep = ({
  types,
  containers,
}: {
  types: NextStepChannelTypes[];
  containers: ContainerChannelType[];
}): boolean => {
  if (types) {
    try {
      if (types.includes('display')) {
        return false;
      }
      if (types.includes('html') && containers.includes('iframe')) {
        return false;
      }
    } catch (e) {
      return true;
    }
  }
  return true;
};

export const WindowHelper = {
  /**
   *
   * @param name
   * @param path
   */
  open(name: string, path?: string): void {
    if (!this.exist(name)) {
      const newWindow = window.open(path || '/loading', '_blank');
      if (newWindow) {
        openedWindow[name] = newWindow;
        try {
          newWindow.name = name;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.info('external domain');
        }
      }
      if (!newWindow) {
        this.fallback();
      }
    }
  },
  /**
   *
   * @param name
   */
  close(name: string): void {
    if (this.exist(name)) {
      try {
        openedWindow[name].close();
        delete openedWindow[name];
      } catch (e) {
        // eslint-disable-next-line no-console
        console.info('probably cross origin frame try to close');
      }
    }
  },
  /**
   *
   * @param name
   */
  get(name: string): WindowProxy {
    if (
      openedWindow[name] &&
      !!openedWindow[name].frames &&
      !openedWindow[name].closed
    ) {
      return openedWindow[name];
    }
    return null;
  },
  /**
   *
   * @param name
   */
  exist(name: string): boolean {
    return (
      !!openedWindow[name] &&
      !!openedWindow[name].frames &&
      !openedWindow[name].closed
    );
  },

  /**
   *
   */
  fallback(): void {
    //todo do smth fallback
  },
};
