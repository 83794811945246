import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PaymentMethodInput, ProcessPaymentResponse } from '../../slices';

export interface ConfirmationFormData {
  transactionId: string;
  paymentAttributes: PaymentMethodInput[];
}

export interface ConfirmationDataItem {
  name: string;
  value?: string;
  isValid: boolean;
}

export interface ConfirmationFormState {
  confirmationData: { [name: string]: ConfirmationDataItem };
  fieldConfigurations: PaymentMethodInput[];
  isConfirmationFormLoading: boolean;
}

export const CONFIRMATION_FORM_FEATURE_KEY = 'confirmationForm';

export const initialConfirmationFormState: ConfirmationFormState = {
  confirmationData: {},
  fieldConfigurations: [],
  isConfirmationFormLoading: false,
};

export const confirmationFormSlice = createSlice({
  name: CONFIRMATION_FORM_FEATURE_KEY,
  initialState: initialConfirmationFormState,
  reducers: {
    setConfirmationFormData: (
      state,
      action: PayloadAction<ConfirmationFormData>,
    ) => {
      state.fieldConfigurations = action.payload.paymentAttributes;

      const newConfirmationData = {};
      action.payload.paymentAttributes.forEach((attribute) => {
        const { name, value } = attribute;

        newConfirmationData[name] = { name, value, isValid: Boolean(value) };
      });

      state.confirmationData = newConfirmationData;
    },
    changeConfirmationFieldValue: (
      state,
      action: PayloadAction<{ name: string; value: string; isValid: boolean }>,
    ) => {
      const { name, value, isValid } = action.payload;
      const changedDataItem = {
        ...state.confirmationData[name],
        value,
        isValid,
      };

      state.confirmationData = {
        ...state.confirmationData,
        [name]: changedDataItem,
      };
    },
    resetConfirmationFormData: (state) => {
      state.fieldConfigurations =
        initialConfirmationFormState.fieldConfigurations;
      state.confirmationData = initialConfirmationFormState.confirmationData;
    },
    makeConfirmRequestStart: (state, { payload }: PayloadAction<any>) => {
      state.isConfirmationFormLoading = true;
    },
    makeConfirmRequestSuccess: (
      state,
      action: PayloadAction<ProcessPaymentResponse>,
    ) => {
      state.isConfirmationFormLoading = false;
    },
    makeConfirmRequestFailure: (state, action: PayloadAction<any>) => {
      state.isConfirmationFormLoading = false;
    },
    prepareConfirmRequest: (state) => {
      state.isConfirmationFormLoading = true;
    },
  },
});

export const confirmationFormReducer = confirmationFormSlice.reducer;

export const {
  setConfirmationFormData,
  resetConfirmationFormData,
  makeConfirmRequestStart,
  makeConfirmRequestSuccess,
  makeConfirmRequestFailure,
  changeConfirmationFieldValue,
  prepareConfirmRequest,
} = confirmationFormSlice.actions;
