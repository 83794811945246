import React, { useEffect, useState } from 'react';

import { clipboardService } from '../helpers/copyToClipboard';

export const useCopyToClipboard = () => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [copied, setCopied]);

  const copyToClipboard = React.useCallback((text) => {
    clipboardService.copy(text);
    setCopied(true);
  }, []);

  return {
    copied,
    copyToClipboard,
  };
};
