// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.
/* eslint-disable */
export const environment = {
  production: false,
  paymentIqCardEncrypterUrl: 'https://test-api.paymentiq.io/paymentiq/api/viq/jscardencrypter',
  paymentGatewayApiUrl: 'https://pgateway.demo.dev20bet.com',
  imgCdn: 'https://d30zqsd9mhgdy9.cloudfront.net/',
  directa24DropdownUrl: "https://test-api.paymentiq.io/paymentiq/api/directa24/getBanksByCountry",
  mifinityDropdownUrl: "https://api.paymentiq.io/paymentiq/api/mifinity/banks/CA/CAD",
  sentryKey: 'https://82e8e8f7011f4585ac6dca722e1ce183@sentry.softlabs.com/14',
  sentryEnvironment: 'demo',
  cashierUrl: 'cashier.pgateway.demo.dev20bet.com',
};
