import { CategoryAggregate } from '../slices';

export const getCategoryIdByName = (
  categoryMap: { [id: string]: CategoryAggregate },
  categoryName: string,
): string | null => {
  const foundCategoryIds = [...Object.entries(categoryMap)]
    .filter(({ 1: { name: value } }) => value === categoryName)
    .map(([k]) => k);

  return foundCategoryIds.length ? foundCategoryIds[0] : null;
};
