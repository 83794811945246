export enum AvailablePlatformName {
  platformBase = '1',
  bet20Win = '2',
  tonyNL = '3',
  tonyKE = '4',
  tonyEE = '5',
  tonyLV = '6',
  tonyNG = '7',
  tonyES = '8',
  tonyCA = '9',
  tonyPT = '10',
  tonyAU = '11',
  bet22 = '12',
  bet22Senegal = '13',
  bet22Cameroon = '14',
  bet22Congo = '15',
  bet22Mozambique = '16',
  bet22Zambia = '17',
  bet22Burundi = '18',
  bet22Ghana = '19',
  ivibet = '20',
  nationalBase = '21',
  nationalBizzo = '22',
  hellspinBase = '23',
  sofobetBase = '24',
  vave = '25',
  tonywinae = '26',
  granawin = '27',
  none = '',
  dragonslots = '32',
  slotsgem = '33',
}

const Platforms = {
  nationalcasino: 'national',
  [AvailablePlatformName.nationalBase]: 'national',
  vavecom: 'vave',
  [AvailablePlatformName.vave]: 'vave',
  bizzocasinocom: 'bizzocasinocom',
  [AvailablePlatformName.nationalBizzo]: 'bizzocasinocom',
  hellspincom: 'hellspincom',
  [AvailablePlatformName.hellspinBase]: 'hellspincom',
  '20bet': 'twentybet',
  [AvailablePlatformName.bet20Win]: 'twentybet',
  [AvailablePlatformName.platformBase]: 'twentybet',
  ivibetcom: 'ivibetcom',
  [AvailablePlatformName.ivibet]: 'ivibetcom',
  tonybet: 'tonybet',
  '22bet': 'twentytwobet',
  tonywinae: 'tonywinae',
  [AvailablePlatformName.tonywinae]: 'tonywinae',
  granawincom: 'granawincom',
  [AvailablePlatformName.granawin]: 'granawincom',
  eeivibetcom: 'ivibetcom',
  [AvailablePlatformName.dragonslots]: 'dragonslots',
  [AvailablePlatformName.slotsgem]: 'slotsgem',
};

const TONY_PLATFORMS_LIST = [
  AvailablePlatformName.tonyNL,
  AvailablePlatformName.tonyKE,
  AvailablePlatformName.tonyPT,
  AvailablePlatformName.tonyNG,
  AvailablePlatformName.tonyAU,
  AvailablePlatformName.tonyLV,
  AvailablePlatformName.tonyEE,
  AvailablePlatformName.tonyES,
  AvailablePlatformName.tonyCA,
];

const BET_22_PLATFORMS_LIST = [
  AvailablePlatformName.bet22,
  AvailablePlatformName.bet22Senegal,
  AvailablePlatformName.bet22Cameroon,
  AvailablePlatformName.bet22Congo,
  AvailablePlatformName.bet22Mozambique,
  AvailablePlatformName.bet22Zambia,
  AvailablePlatformName.bet22Burundi,
  AvailablePlatformName.bet22Ghana,
];

export const getPlatformTheme = (platform: string): string => {
  if (Platforms[platform]) {
    return Platforms[platform];
  }

  // todo i'll leave any here, till we have old and new variant of platform name
  if (
    platform.startsWith(Platforms.tonybet) ||
    TONY_PLATFORMS_LIST.includes(platform as any)
  ) {
    return Platforms.tonybet;
  }

  // todo i'll leave any here, till we have old and new variant of platform name
  if (
    platform.startsWith('22bet') ||
    BET_22_PLATFORMS_LIST.includes(platform as any)
  ) {
    return Platforms['22bet'];
  }

  return '';
};
