import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Fetcher, FetcherApi, ServerResponse } from '../http';

export type errorType = (
  error: ServerResponse<any>,
) => Observable<ServerResponse<any>>;

export interface SwitcherApi {
  get<T>(
    url: string,
    params?: object,
    entityName?: string,
    headers?: HeadersInit,
  ): Observable<T>;
  post<T>(
    url: string,
    params?: object | File,
    entityName?: string,
    headers?: HeadersInit,
  ): Observable<T>;
  put<T>(
    url: string,
    params?: object,
    entityName?: string,
    headers?: HeadersInit,
  ): Observable<T>;
}

export abstract class ClientSwitcher implements SwitcherApi {
  private fetchModel: FetcherApi | any;

  private errorHandler = (error) => {
    if (
      error.status === 'fail' ||
      error.status === 'error' ||
      `${error.status}` === '0' ||
      Number(error.status) >= 400
    ) {
      if (Number(error.code) === 400) {
        error['validation'] = { ...error.data.data };
      }
      return throwError(error);
    }
    return of(error);
  };

  constructor(
    private apiUrl: string,
    public paymentiqTestApi?: string,
    public customApi?: { [key: string]: string },
  ) {
    this.setHttpModel();
  }

  private setHttpModel() {
    this.fetchModel = Fetcher;
  }

  get<T>(
    url: string,
    params?: object,
    entityName?: string,
    headers?: HeadersInit,
  ): Observable<T> {
    if (url.startsWith('/')) {
      url = url.substr(1);
    }
    const request = this.fetchModel.get(
      `${this.apiUrl}/${url}`,
      params ? params : null,
      entityName,
      headers,
    );
    return request.pipe(catchError((err) => this.errorHandler(err)));
  }

  post<T>(
    url: string,
    params?: object | string | File,
    entityName?: string,
    headers?: Headers | string[][] | Record<string, string>,
  ): Observable<T> {
    if (url.startsWith('/')) {
      url = url.substr(1);
    }
    const request = this.fetchModel.post(
      `${this.apiUrl}/${url}`,
      params,
      null,
      headers,
    );
    return request.pipe(catchError((err) => this.errorHandler(err)));
  }

  put<T>(
    url: string,
    params?: object | string,
    entityName?: string,
    headers?: Headers | string[][] | Record<string, string>,
  ): Observable<T> {
    if (url.startsWith('/')) {
      url = url.substr(1);
    }
    const request = this.fetchModel.put(
      `${this.apiUrl}/${url}`,
      params,
      null,
      headers,
    );
    return request.pipe(catchError((err) => this.errorHandler(err)));
  }

  getWithoutApiUrl<T>(
    url: string,
    params?: object | string,
    entityName?: string,
    headers?: Headers | string[][] | Record<string, string>,
  ): Observable<T> {
    if (url.startsWith('/')) {
      url = url.substr(1);
    }
    const request = this.fetchModel.get(url, params, null, headers);
    return request.pipe(catchError((err) => this.errorHandler(err)));
  }

  postWithoutApiUrl<T>(
    url: string,
    params?: object | string,
    entityName?: string,
    headers?: Headers | string[][] | Record<string, string>,
  ): Observable<T> {
    if (url.startsWith('/')) {
      url = url.substr(1);
    }
    const request = this.fetchModel.post(url, params, null, headers);
    return request.pipe(catchError((err) => this.errorHandler(err)));
  }
}
