import { useSelector } from 'react-redux';
import i18n from 'i18next';

import { validateByCharactersType, validateByLimits } from '../helpers';
import { PaymentMethod, selectAmount, selectPaymentMethod } from '../slices';

const getAmountScaleValidationError = (
  method: PaymentMethod,
  amount: string,
): string | null => {
  if (!amount) return null;

  const amountFieldConfig = method?.inputs.find(
    (input) => input.name === 'amount',
  );
  if (!amountFieldConfig) {
    return null;
  }

  const { scale } = amountFieldConfig.params;
  const absoluteScale = Math.abs(scale);

  if (typeof scale === 'undefined') return null;

  if (
    Math.sign(scale) === -1 &&
    Number(amount) % Math.pow(10, absoluteScale) !== 0
  ) {
    return i18n.t(
      'amountMultipleOfTensValidationFailed.message',
      'amountMultipleOfTensValidationFailed.message',
      { multipleTo: Math.pow(10, absoluteScale) },
    );
  }

  if (Math.sign(scale) === 1) {
    const amountParts = String(amount).split('.');
    if (amountParts.length !== 1 && amountParts[1].length > absoluteScale) {
      return i18n.t(
        'amountDecimalValidationFailed.message',
        'amountDecimalValidationFailed.message',
        { decimalCount: absoluteScale },
      );
    }
  }

  if (Math.sign(scale) === 0 && Math.floor(Number(amount)) !== Number(amount)) {
    return i18n.t('amountWholeNumberValidationFailed.message');
  }

  return null;
};

export const useAmountValidationErrors = (): string[] => {
  const method = useSelector(selectPaymentMethod);
  const amount = useSelector(selectAmount);

  const errors = [];

  if (!amount) return errors;

  if (!validateByCharactersType(amount)) {
    errors.push(i18n.t('amountCharactersInvalid.message'));

    return errors;
  }

  const { ruleParams } = method;
  const { min, max } = ruleParams || {};
  if (!validateByLimits(amount, min, max)) {
    errors.push(i18n.t('minMaxValidationFailed.message'));
  }

  const amountScaleValidationError = getAmountScaleValidationError(
    method,
    amount,
  );
  if (amountScaleValidationError) {
    errors.push(amountScaleValidationError);
  }

  return errors;
};
