export const convertToUrl = (host: string, parameterBag: object): string => {
  const fields = Object.entries(parameterBag).map(([k, v]) => {
    const name = decodeURIComponent(k);
    const value = decodeURIComponent(v);

    return `${name}=${value}`;
  });

  return fields.length ? `${host}?${fields.join('&')}` : host;
};
