export const generateCardYears = (): { value: string; label: string }[] => {
  const currentYear = new Date().getFullYear();
  const yearArray = [];

  for (let i = 0; i <= 10; i++) {
    const year = currentYear + i;
    const yearObject = {
      value: String(year),
      label: String(year),
    };

    yearArray.push(yearObject);
  }

  return yearArray;
};
