import { createSelector } from '@reduxjs/toolkit';

import {
  PaymentInfoState,
  PAYMENTS_DATA_FEATURE_KEY,
  TransactionStates,
} from './';

export const getPaymentInfoState = (rootState: any): PaymentInfoState =>
  rootState[PAYMENTS_DATA_FEATURE_KEY];

export const selectPaymentCurrentState = createSelector(
  getPaymentInfoState,
  (s) => (s.paymentInfo?.state as TransactionStates) || null,
);
