import { createSelector } from '@reduxjs/toolkit';

import { PAYMENT_REGISTRATION_FEATURE_KEY, PaymentRegistrationState } from './';

export const getRegistrationState = (
  rootState: any,
): PaymentRegistrationState => rootState[PAYMENT_REGISTRATION_FEATURE_KEY];

export const selectRegistrationLoading = createSelector(
  getRegistrationState,
  (s) => s.isRegistrationLoading,
);

export const selectRegistrationResponse = createSelector(
  getRegistrationState,
  (s) => s.registrationResponse,
);

export const selectRegistrationResponseSuccess = createSelector(
  getRegistrationState,
  (s) => s.isRegistrationSuccessed,
);

export const selectRegistrationResponseFailed = createSelector(
  getRegistrationState,
  (s) => s.isRegistrationFailed,
);
