import { createSelector } from '@reduxjs/toolkit';

import { PaymentFormState } from '../';
import { PAYMENT_FORM_FEATURE_KEY } from './index';

export const getPaymentFormState = (rootState: any): PaymentFormState =>
  rootState[PAYMENT_FORM_FEATURE_KEY];

export const selectPaymentInputs = createSelector(
  getPaymentFormState,
  (s) => s.paymentInputs,
);

export const selectDropdownOptions = createSelector(
  getPaymentFormState,
  (s) => s.options,
);
