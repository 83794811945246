/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { initReactI18next } from "react-i18next";
import i18n from "i18next";

// eslint-disable-next-line import/no-unresolved
import { convertToLocale } from "@cashier/cashier-common";

// eslint-disable-next-line import/extensions
import { environment } from "./environments/environment";

const initI18n = () => {
  i18n
    .use(initReactI18next)
    .init({
      lng: 'en', // Set a default language
      fallbackLng: 'en',
      load: 'currentOnly',
      keySeparator: false,
      namespaceSeparator: false,
    });
};

const loadTranslations = () => {
  // eslint-disable-next-line no-restricted-globals
  const params = new URLSearchParams(location.search);

  const lng = convertToLocale(params.get('language'));
  const country = params.get('country') ?? '';
  const merchantId = params.get('merchantId');
  const userId = params.get('userId');
  const sessionId = params.get('sessionId');

  if (!merchantId || !userId || !sessionId) {
    return;
  }

  const token = btoa(`${merchantId}:${userId}:${sessionId}`);

  if (merchantId && userId && sessionId) {
    fetch(`${environment.paymentGatewayApiUrl}/api/v1/cashier/translations?country=${country}`, {
      headers: {
        'Pgw-Authorization': token,
        'Accept-Language': convertToLocale(lng),
      },
    })
      .then(resp => resp.json())
      .then(resp => {
        i18n.addResources(lng, 'translation', resp.data);
        i18n.changeLanguage(lng);
      });
  }
};

export { i18n, initI18n, loadTranslations };
