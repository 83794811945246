import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AMOUNT_FIELD_NAME } from '../../constants';
import { TransactionType } from '../../interfaces';
import { FieldType } from '../../models';
import {
  Category,
  CategoryAggregate,
  FetchPaymentMethodsInput,
  PaymentMethodShort,
  PaymentMethodsState,
} from './interfaces';

export const inputIconsMap = {
  [FieldType.Cvv]: 'assets/icons/lock-solid.svg',
  [FieldType.DateTime]: 'assets/icons/calendar.svg',
};

export const inputMasksMap = {
  [FieldType.DateTime]: '99/9999',
};

export const DEFAULT_CATEGORY_NAME = 'recommended';

const getDefaultPaymentMethod = (categoryMap: {
  [id: string]: CategoryAggregate;
}): PaymentMethodShort | null => {
  const categoryEntries = [...Object.entries(categoryMap)];
  if (!categoryEntries.length) {
    return null;
  }

  const defaultCategories = categoryEntries
    .filter(({ 1: { name: value } }) => value === DEFAULT_CATEGORY_NAME)
    .map(([k]) => k);

  const [defaultCategoryKey] = defaultCategories.length
    ? defaultCategories
    : Object.keys(categoryMap);

  return categoryMap[defaultCategoryKey].paymentMethods[0];
};

export const PAYMENT_METHODS_FEATURE_KEY = 'paymentMethods';

export const initialPaymentMethodsState: PaymentMethodsState = {
  arePaymentMethodsLoading: false,
  loadCertainPaymentMethod: false,
  isPaymentMethodSelectedManually: false /* for the needs of mobile version */,
  paymentMethod: {
    id: '',
    name: '',
    vendor: '',
    inputs: [],
    activeInputs: [],
    account_form_inputs: [],
    img: '',
    categories: [],
    default_amounts: [],
    logo: '',
    ruleParams: {
      min: null,
      max: null,
      recommended: false,
      amounts: [],
    },
    next_step_info: {
      channel: null,
      post_confirm: null,
      post_process: null,
    },
    order: null,
    description: '',
    multistep: false,
    source: null,
    referenceId: '',
    hideAmount: false,
  },
  bonus: {
    amountIntervals: null,
    cashierConfiguration: {
      displayBonusFastSums: false,
      displayBonusCalculator: false,
      iconURL: '',
    },
    id: '',
  },
  categoryMap: {},
  isCategoryFilterActivated: false,
  activeCategory: null,
  country: null,
  paymentIqMerchantId: null,
  registrationRequired: false,
  tax: {},
};

export const paymentMethodsSlice = createSlice({
  name: PAYMENT_METHODS_FEATURE_KEY,
  initialState: initialPaymentMethodsState,
  reducers: {
    getPaymentMethodsStart: (
      state,
      action: PayloadAction<FetchPaymentMethodsInput>,
    ) => {
      state.arePaymentMethodsLoading = true;
    },
    getPaymentMethodsSuccess: (
      state,
      action: PayloadAction<{
        categories: Category[];
        payment_methods: PaymentMethodShort[];
      }>,
    ) => {
      state.arePaymentMethodsLoading = false;
      const { categories, payment_methods } = action.payload;
      const categoryMap = {};

      categories.forEach((category) => {
        const { id, name, label } = category;
        const filteredPaymentMethods = payment_methods.filter(
          ({ categories }) => categories.includes(id),
        );

        if (filteredPaymentMethods.length) {
          categoryMap[id] = {
            name,
            label,
            paymentMethods: filteredPaymentMethods,
          };
        }
      });

      state.categoryMap = categoryMap;

      const defaultPaymentMethod = getDefaultPaymentMethod(state.categoryMap);
      if (defaultPaymentMethod) {
        const { categories, ...paymentMethod } = defaultPaymentMethod;

        state.paymentMethod = {
          ...initialPaymentMethodsState.paymentMethod,
          ...paymentMethod,
        };
        state.loadCertainPaymentMethod = true;
      } else {
        state.paymentMethod = initialPaymentMethodsState.paymentMethod;
      }
    },
    getPaymentMethodsFailure: (state) => {
      state.arePaymentMethodsLoading = false;
    },
    choosePaymentMethod: (state, action: PayloadAction<PaymentMethodShort>) => {
      if (state.paymentMethod.id !== action.payload.id) {
        const { categories, ...paymentMethod } = action.payload;
        state.paymentMethod = {
          ...initialPaymentMethodsState.paymentMethod,
          ...paymentMethod,
        };
        state.loadCertainPaymentMethod = true;
      }

      state.isPaymentMethodSelectedManually = true;
    },
    resetPaymentMethod: (state) => {
      const defaultPaymentMethod = getDefaultPaymentMethod(state.categoryMap);

      if (defaultPaymentMethod) {
        if (defaultPaymentMethod?.id !== state.paymentMethod.id) {
          const { categories, ...paymentMethod } = defaultPaymentMethod;
          state.paymentMethod = {
            ...initialPaymentMethodsState.paymentMethod,
            ...paymentMethod,
          };
        }

        state.loadCertainPaymentMethod = true;
      }

      state.isPaymentMethodSelectedManually = false;
    },
    getCertainPaymentMethodStart: (
      state,
      {
        payload,
      }: PayloadAction<{
        transactionType: TransactionType;
        paymentMethodId: string;
        bonusCode?: string;
      }>,
    ) => {
      state.arePaymentMethodsLoading = true;
      state.paymentMethod = { ...state.paymentMethod, inputs: [] };
    },
    getCertainPaymentMethodSuccess: (
      state,
      { payload }: PayloadAction<any>,
    ) => {
      const { method } = payload;
      state.paymentMethod = {
        ...state.paymentMethod,
        ...method,
        hideAmount: !payload.method.inputs.find(
          ({ name }) => name === AMOUNT_FIELD_NAME,
        ),
      };
      state.bonus = payload.attributes.bonus;
      state.paymentIqMerchantId = payload.attributes?.payment_iq_merchant_id;
      state.tax = payload.attributes?.tax || {};
      state.country = payload.country;
      state.registrationRequired = payload.registrationRequired;
      state.arePaymentMethodsLoading = false;
      state.loadCertainPaymentMethod = false;
    },
    getCertainPaymentMethodFailure: (state) => {
      state.arePaymentMethodsLoading = false;
      state.loadCertainPaymentMethod = false;
    },
    toggleCategoryFilterActivation: (state) => {
      state.isCategoryFilterActivated = !state.isCategoryFilterActivated;
    },
    setActiveCategory: (state, action: PayloadAction<string>) => {
      state.activeCategory = action.payload;

      if (Object.keys(state.categoryMap).length) {
        const [firstCategoryId] = Object.keys(state.categoryMap);

        const {
          paymentMethods: [firstMethod],
        } =
          state.categoryMap[action.payload] ||
          state.categoryMap[firstCategoryId];
        const { categories, ...paymentMethod } = firstMethod;

        if (state.paymentMethod.id !== paymentMethod.id) {
          state.paymentMethod = {
            ...state.paymentMethod,
            ...paymentMethod,
          };
          state.loadCertainPaymentMethod = true;
        }
      }

      state.isCategoryFilterActivated = false;
    },
  },
});

export const paymentMethodsReducer = paymentMethodsSlice.reducer;

export const {
  getPaymentMethodsStart,
  getPaymentMethodsSuccess,
  getPaymentMethodsFailure,
  choosePaymentMethod,
  resetPaymentMethod,
  getCertainPaymentMethodStart,
  getCertainPaymentMethodSuccess,
  getCertainPaymentMethodFailure,
  toggleCategoryFilterActivation,
  setActiveCategory,
} = paymentMethodsSlice.actions;
