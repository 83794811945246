import { CashierFieldPreprocessor } from '../../models';
import { Preprocessor } from '../../slices';

declare const encryptData: (data: string) => string;

export const convertData = (
  data: string,
  preprocessor: Preprocessor | null,
): string => {
  if (!preprocessor) {
    return data;
  }

  let convertedData = '';

  switch (preprocessor.type) {
    case CashierFieldPreprocessor.PIQ_ENCRYPTOR:
      convertedData = encryptData(data.replace(/\s/g, ''));
      break;
  }

  return convertedData || data;
};
