export const componentsName = {
  amount_input: 'amount_input',
  amount_select: 'amount_select',
  dynamic_select: 'dynamic_select',
  select: 'select',
  date_picker: 'date_picker',
  cvv: 'cvv',
  input: 'input',
  text_field: 'text_field',
  number: 'number',
};
