import { CashierCommunicationTasks, executeTask } from '../models';
import {
  choosePaymentMethod,
  DynamicScreens,
  getPaymentInfoStart,
  paymentCurrentStateSuccess,
  PaymentTransaction,
  ProcessPaymentResponse,
  processPaymentSuccess,
  setActiveScreen,
  TransactionStates,
} from '../slices';

export const communicationTasksMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const communicationTaskMap = {
    [TransactionStates.SUCCESSFUL]: CashierCommunicationTasks.processingResult,
    [TransactionStates.FAILED]: CashierCommunicationTasks.transactionFailed,
    [TransactionStates.CANCELLED]:
      CashierCommunicationTasks.transactionCanceled,
  };

  const notifyAboutPayment = (
    state: string,
    transactionId: string,
    amount: string,
  ): void => {
    executeTask(communicationTaskMap[state], { transactionId, state, amount });
  };

  if (action.type === paymentCurrentStateSuccess.toString()) {
    const { payload }: { payload: PaymentTransaction } = action;
    const {
      customerInformation: { activeScreen, amount },
    } = store.getState();

    if (
      activeScreen !== DynamicScreens.paymentForm &&
      communicationTaskMap[payload.state]
    ) {
      notifyAboutPayment(payload.state, payload.transactionId, amount);
    }
  }

  if (action.type === processPaymentSuccess.toString()) {
    const { payload }: { payload: ProcessPaymentResponse } = action;
    const {
      customerInformation: { amount },
    } = store.getState();

    if (communicationTaskMap[payload.transactionState]) {
      notifyAboutPayment(
        payload.transactionState,
        payload.transactionId,
        amount,
      );
    }
  }

  if (
    [
      getPaymentInfoStart.toString(),
      choosePaymentMethod.toString(),
      setActiveScreen.toString(),
    ].includes(action.type) ||
    action.payload === DynamicScreens.processingResult ||
    action.payload === DynamicScreens.paymentForm
  ) {
    executeTask(CashierCommunicationTasks.scrollTop);
  }

  return result;
};
