import { FIAT_AMOUNT } from '../../constants';
import { FieldType } from '../../models';
import { convertRegexFromString } from '../convertRegexFromString';
import { CommonValidator } from './commonValidator';

const allowedCreditTypes = [
  FieldType.CreditCardNumber,
  FieldType.CreditCardVisa,
  FieldType.CreditCardMastercard,
  FieldType.CardNumber,
];

const validationMask = {
  amount: {
    pattern: /^[.\d,]+$/,
    validator: (inputRef) => {
      inputRef.current.value = inputRef.current.value
        .replace(/[^.\d,]/g, '')
        .trim();
    },
  },
  cardHolder: {
    pattern: /^[a-zA-Z\s]+$/,
    validator: (inputRef) => {
      inputRef.current.value = inputRef.current.value
        .replace(/[^a-zA-Z\s]/g, '')
        .slice(0, 50);
    },
  },
  cvv: {
    pattern: '',
    validator: (inputRef) => {
      if (inputRef.current.value.length > 4) {
        inputRef.current.value = inputRef.current.value.substring(0, 4);
      }
    },
  },
  identification_number: {
    pattern: '',
    validator: (inputRef) => {
      const hasOnlyIntegers = !!inputRef.current.value.match(/^\d+$/);

      if (!hasOnlyIntegers) {
        inputRef.current.value = inputRef.current.value.substring(
          0,
          inputRef.current.value.length - 1,
        );
      }

      if (inputRef.current.value.length > 11) {
        inputRef.current.value = inputRef.current.value.substring(0, 11);
      }
    },
  },
  integer: {
    pattern: '',
    validator: (inputRef) => {
      inputRef.current.value = inputRef.current.value.replace(/\D/g, '');
    },
  },
};

const validationRules = {
  cardHolder: {
    validator: (inputRef) => {
      return /^[a-zA-Z\s]{3,50}$/.test(inputRef.current.value);
    },
  },
};

export const validateField = (
  inputRef,
  type,
  name,
  pattern,
  required,
  params,
) => {
  const maskValidation = validationMask[name];
  const ruleValidation = validationRules[name];

  if (maskValidation) {
    inputRef.current.pattern = maskValidation?.pattern;
    maskValidation.validator(inputRef);
  }

  if (pattern && name !== FIAT_AMOUNT) {
    inputRef.current.pattern = pattern;
    if (!convertRegexFromString(pattern).test(inputRef.current.value)) {
      return false;
    }
  }

  if (ruleValidation) {
    return ruleValidation.validator(inputRef);
  }

  if (allowedCreditTypes.includes(type as FieldType)) {
    inputRef.current.value = inputRef.current.value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();

    if (inputRef.current.value.length > 23) {
      inputRef.current.value = inputRef.current.value.substring(0, 23);
    }
  }

  if (CommonValidator[type]) {
    return CommonValidator[type](inputRef.current, params);
  }

  return !(!inputRef.current.value && required);
};
