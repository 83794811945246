import { createSelector } from '@reduxjs/toolkit';

import {
  selectAccountCreateInProgress,
  selectActiveAccount,
  selectPaymentMethod,
} from '../slices';

export const selectActiveInputs = createSelector(
  selectPaymentMethod,
  selectAccountCreateInProgress,
  selectActiveAccount,
  (method, isInProgress, account) => {
    const { inputs, account_form_inputs, show_account_form } = method || {};

    return isInProgress || !account || !show_account_form
      ? inputs
      : account_form_inputs;
  },
);
