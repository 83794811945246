import { FinalStates } from '../constants';
import { CashierCommunicationTasks, executeTask } from '../models';
import { initiateNewTransaction, TransactionStates } from '../slices';

export const paymentInfoMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const {
    transaction_processing,
    paymentInfo: { paymentInfo },
  } = store.getState();

  const forbidToSendGetBackPostMessage = [
    TransactionStates.CANCELLED,
    TransactionStates.FAILED,
  ].includes(paymentInfo?.state);

  if (
    action.type === initiateNewTransaction.toString() &&
    transaction_processing?.processingResponse.status !== FinalStates.error &&
    !forbidToSendGetBackPostMessage
  ) {
    executeTask(CashierCommunicationTasks.getBackToPaymentMethods);
  }

  return result;
};
