import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  makeDirecta24DropdownRequestFailure,
  makeDirecta24DropdownRequestStart,
  makeDirecta24DropdownRequestSuccess,
  makeOptionsRequestStart,
  makeOptionsRequestSuccess,
} from '../../slices';

export const makeDirecta24DropdownRequestEpic = (action$, state$, { Api }) =>
  action$.pipe(
    ofType(makeDirecta24DropdownRequestStart.type),
    switchMap((action: { payload: { url; parameters } }) =>
      Api.makeDirecta24DropdownRequest(
        action.payload.url,
        action.payload.parameters,
      ).pipe(
        map((data: any) => makeDirecta24DropdownRequestSuccess(data)),
        catchError((err) => of(makeDirecta24DropdownRequestFailure(err))),
      ),
    ),
  );

export const makeMifinityDropdownRequestEpic = (action$, state$, { Api }) =>
  action$.pipe(
    ofType(makeOptionsRequestStart.type),
    switchMap((action: { payload: { url } }) =>
      fetch(action.payload.url)
        .then((res) => res.arrayBuffer())
        .then((res) => {
          const decoder = new TextDecoder('iso-8859-1');
          const { payload } = JSON.parse(decoder.decode(res));
          return makeOptionsRequestSuccess(payload);
        }),
    ),
  );
