import { createSelector } from '@reduxjs/toolkit';
import getSymbolFromCurrency from 'currency-symbol-map';

import {
  CUSTOMER_INFORMATION_KEY,
  CustomerInformation,
  DynamicScreens,
} from './index';

export const getCustomerInformation = (rootState): CustomerInformation =>
  rootState[CUSTOMER_INFORMATION_KEY];

export const selectActiveScreen = createSelector(
  getCustomerInformation,
  (s) => s.activeScreen,
);

export const selectAmount = createSelector(
  getCustomerInformation,
  (s) => s.amount,
);

export const selectBonusCode = createSelector(
  getCustomerInformation,
  (s) => s.bonusCode,
);

export const selectCurrencySymbol = createSelector(
  getCustomerInformation,
  (s) => getSymbolFromCurrency(s.currency),
);

export const selectFinalScreenActivated = createSelector(
  getCustomerInformation,
  (s) => s.activeScreen === DynamicScreens.finalScreen,
);

export const selectProcessingResultSectionActivated = createSelector(
  getCustomerInformation,
  (s) => s.activeScreen === DynamicScreens.processingResult,
);

export const selectPaymentFormActivated = createSelector(
  getCustomerInformation,
  (s) => s.activeScreen === DynamicScreens.paymentForm,
);

export const selectConfirmationFormActivated = createSelector(
  getCustomerInformation,
  (s) => s.activeScreen === DynamicScreens.confirmationForm,
);
