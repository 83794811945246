export enum InputMode {
  Numeric = 'numeric',
  DECIMAL = 'decimal',
}

export enum Themes {
  default = 'default',
  platform = 'platform',
}

export enum TransactionType {
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
}

export enum Theme {
  Light = 'light',
  Dark = 'dark',
}

export enum IntegrationSource {
  Internal = 'internal',
  External = 'external',
}

export enum FinalScreenTypes {
  Display = 'display',
  Standard = 'standard',
}
