import { combineReducers } from '@reduxjs/toolkit';

import {
  ACCOUNTS_FEATURE_KEY,
  accountsReducer,
  CASHIER_CONFIG_FEATURE_KEY,
  cashierConfigReducer,
  CONFIRMATION_FORM_FEATURE_KEY,
  confirmationFormReducer,
  CUSTOMER_INFORMATION_KEY,
  customerInformationReducer,
  FILE_UPLOADING_FEATURE_KEY,
  fileUploadingReducer,
  PAYMENT_FORM_FEATURE_KEY,
  PAYMENT_METHODS_FEATURE_KEY,
  PAYMENT_PROCESSING_FEATURE_KEY,
  PAYMENT_REGISTRATION_FEATURE_KEY,
  paymentFormReducer,
  paymentInfoReducer,
  paymentMethodsReducer,
  paymentProcessingReducer,
  paymentRegistrationReducer,
  PAYMENTS_DATA_FEATURE_KEY,
} from '../slices';

export const rootReducer = combineReducers({
  [ACCOUNTS_FEATURE_KEY]: accountsReducer,
  [PAYMENTS_DATA_FEATURE_KEY]: paymentInfoReducer,
  [PAYMENT_FORM_FEATURE_KEY]: paymentFormReducer,
  [CASHIER_CONFIG_FEATURE_KEY]: cashierConfigReducer,
  [CONFIRMATION_FORM_FEATURE_KEY]: confirmationFormReducer,
  [PAYMENT_METHODS_FEATURE_KEY]: paymentMethodsReducer,
  [PAYMENT_PROCESSING_FEATURE_KEY]: paymentProcessingReducer,
  [PAYMENT_REGISTRATION_FEATURE_KEY]: paymentRegistrationReducer,
  [CUSTOMER_INFORMATION_KEY]: customerInformationReducer,
  [FILE_UPLOADING_FEATURE_KEY]: fileUploadingReducer,
});
