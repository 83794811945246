import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { isTransactionSuccessOrPending } from '../../helpers';
import {
  processPaymentFailure,
  ProcessPaymentRequest,
  ProcessPaymentResponse,
  processPaymentStart,
  processPaymentSuccess,
} from '../../slices';

export const paymentProcessingEpic = (action$, state$, { Api }) =>
  action$.pipe(
    ofType(processPaymentStart.type),
    switchMap((action: { payload: ProcessPaymentRequest }) =>
      Api.processPayment(action.payload).pipe(
        map((data: ProcessPaymentResponse) => {
          //@TODO: replace this complex transaction check for all available stateFlags from action to reducer
          if (
            isTransactionSuccessOrPending(
              action.payload.paymentType,
              data.transactionState || data.status,
            ) ||
            'resultAction' in data
          )
            return processPaymentSuccess(data);

          return processPaymentFailure(data);
        }),
        catchError((err) => of(processPaymentFailure(err))),
      ),
    ),
  );
