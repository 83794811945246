import { combineEpics } from 'redux-observable';

import { makeConfirmRequestEpic } from './confirmationForm';
import { uploadFileEpic } from './fileUploading';
import {
  makeDirecta24DropdownRequestEpic,
  makeMifinityDropdownRequestEpic,
} from './paymentForm';
import {
  fetchCurrentStateRequestEpic,
  fetchPaymentInfoEpic,
} from './paymentInfo';
import {
  fetchCertainPaymentMethodEpic,
  fetchPaymentMethodsEpic,
} from './paymentMethods';
import { paymentProcessingEpic } from './paymentProcessing';
import { paymentRegistrationEpic } from './paymentRegistration';

export const paymentsEpics = combineEpics(
  paymentProcessingEpic,
  paymentRegistrationEpic,
  fetchPaymentInfoEpic,
  makeDirecta24DropdownRequestEpic,
  makeConfirmRequestEpic,
  fetchCertainPaymentMethodEpic,
  fetchCurrentStateRequestEpic,
  fetchPaymentMethodsEpic,
  makeMifinityDropdownRequestEpic,
  uploadFileEpic,
);
