import { ResultContainerTypes } from '../slices';

export const isFinalScreenResolved = (payload): boolean => {
  /* code smells (very complex condition) */
  return (
    !(
      payload.resultAction ||
      payload.redirectOutput ||
      payload.paymentAttributes ||
      payload.response?.data ||
      payload.response?.errors
    ) ||
    (payload.resultAction?.type === ResultContainerTypes.DISPLAY &&
      payload?.status !== 400)
  );
};
