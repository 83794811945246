import { uploadFile } from '../epics/fileUploading';
import { FieldType } from '../models';
import {
  changeConfirmationFieldValue,
  ConfirmationDataItem,
  makeConfirmRequestStart,
  prepareConfirmRequest,
  uploadFileSuccess,
} from '../slices';

export const confirmationMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  if (action.type === prepareConfirmRequest.toString()) {
    const {
      confirmationForm,
      transaction_processing: {
        processingResponse: { transactionId },
      },
    } = store.getState();

    const confirmationData = confirmationForm.confirmationData as {
      [name: string]: ConfirmationDataItem;
    };
    const fileField = confirmationForm.fieldConfigurations.find(
      (field) => field.type === FieldType.FileLink,
    );

    const parameterMap = {};
    Object.values(confirmationData).forEach((item) => {
      const { name, value } = item;

      parameterMap[name] = typeof value === 'string' ? value.trim() : value;
    });

    if (fileField && !parameterMap[fileField?.name]) {
      const {
        fileUploading: { data },
      } = store.getState();

      data && store.dispatch(uploadFile(data, transactionId));

      return result;
    }

    store.dispatch(
      makeConfirmRequestStart({
        transactionId: transactionId,
        paymentAttributes: parameterMap,
      }),
    );
  }

  if (action.type === uploadFileSuccess.toString()) {
    const {
      confirmationForm: { fieldConfigurations },
    } = store.getState();

    const fileField = fieldConfigurations.find(
      (field) => field.name === action.payload.name,
    );

    store.dispatch(
      changeConfirmationFieldValue({
        name: fileField?.name,
        value: action.payload.link,
        isValid: true,
      }),
    );

    store.dispatch(prepareConfirmRequest());
  }

  return result;
};
