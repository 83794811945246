import { Observable, of, throwError } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { AjaxObservable } from 'rxjs/internal-compatibility';
import { filter, map, switchMap } from 'rxjs/operators';
import * as queryString from 'x-query-string';

import { convertToLocale } from '../lib/helpers/convertToLocale';

export interface ServerResponse<T> extends ServerError {
  status: string;
  code?: number;
  data?: T | any;
}

export interface ServerError {
  message: string | string[];
}

const fetchParams: HeadersInit = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

interface IParams {
  [key: string]: string;
}

export interface FetcherApi {
  get<T>(
    path: string,
    params?: IParams,
    entityName?: string,
    headers?: HeadersInit,
  ): Observable<T>;
  post<T>(
    path: string,
    params: IParams | File,
    entityName?: string,
    headers?: HeadersInit,
  ): Observable<T>;
  put<T>(
    path: string,
    params: IParams,
    entityName?: string,
    headers?: HeadersInit,
  ): Observable<T>;
}

const params = new URLSearchParams(window.location.search);
const language = convertToLocale(params.get('language'));

const setHeaders = (headers: HeadersInit) => {
  let secureHeaders = { ...headers } || {};
  secureHeaders = { ...secureHeaders };
  return secureHeaders;
};

export function ajaxPost(
  url: string,
  body?: any,
  headers?: HeadersInit,
  method: string = 'POST',
): Observable<any> {
  return new AjaxObservable<AjaxResponse>({ url, body, headers, method });
}

function toData(res) {
  if (('message' in res || 'messages' in res) && 'data' in res) {
    return {
      ...res,
      data: {
        data: res.data,
        messages: res.message ? res.message : res.messages,
      },
    };
  }

  if ('data' in res) {
    return res;
  }

  return { data: res, status: res.status };
}

const mapResponse = map((x: AjaxResponse) => x.response);

export const ajaxGetJSON = <T>(
  url: string,
  headers?: HeadersInit,
): Observable<T> => {
  return mapResponse(
    new AjaxObservable<AjaxResponse>({
      headers,
      url,
      method: 'GET',
      responseType: 'json',
    }),
  );
};

export const Fetcher: FetcherApi = {
  get: <T>(
    path: string,
    params?: IParams,
    entityName?: string,
    headers?: HeadersInit,
  ): Observable<T> => {
    const url = params ? `${path}?${queryString.encode(params)}` : path;
    const httpHeaders = {
      ...fetchParams,
      ...headers,
      ...{ 'X-Requested-With': 'XMLHttpRequest', 'Accept-Language': language },
    };
    return ajaxGetJSON(url, setHeaders(httpHeaders)).pipe(
      filter((res) => !!res),
      map((res: ServerResponse<T>) => toData(res)),
      switchMap((res: ServerResponse<T>) =>
        ['error', 'fail'].includes(res.status) ? throwError(res) : of(res),
      ),
      map((res: ServerResponse<T>) =>
        entityName ? res.data[entityName] : res.data ? res.data : res,
      ),
    );
  },
  post: <T>(
    path: string,
    params: any | File,
    entityName?: string,
    headers?: HeadersInit,
  ): Observable<T> => {
    const httpHeaders = Object.assign(headers || fetchParams, {
      'X-Requested-With': 'XMLHttpRequest',
      'Accept-Language': language,
    });
    const isFileBody = params instanceof File;

    let body = isFileBody ? params : { ...params };

    if (
      !!headers &&
      'Content-Type' in headers &&
      'application/x-www-form-urlencoded' === headers['Content-Type'] &&
      !isFileBody
    ) {
      body = queryString.encode(params);
    }

    return ajaxPost(path, body, setHeaders(httpHeaders)).pipe(
      map((res) => res.response),
      map((res: ServerResponse<T>) => toData(res)),
      switchMap((res: ServerResponse<T>) =>
        ['error', 'fail'].includes(res.status) ? throwError(res) : of(res),
      ),
      map((res: ServerResponse<T>) =>
        entityName ? res.data[entityName] : res.data ? res.data : res,
      ),
    );
  },
  put: <T>(
    path: string,
    params: object,
    entityName?: string,
    headers?: HeadersInit,
  ): Observable<T> => {
    const httpHeaders = Object.assign(headers || fetchParams, {
      'X-Requested-With': 'XMLHttpRequest',
      'Accept-Language': language,
    });
    let body = { ...params };

    if (
      !!headers &&
      'Content-Type' in headers &&
      'application/x-www-form-urlencoded' === headers['Content-Type']
    ) {
      body = queryString.encode(params);
    }

    return ajaxPost(path, body, setHeaders(httpHeaders), 'PUT').pipe(
      map((res) => res.response),
      map((res: ServerResponse<T>) => toData(res)),
      switchMap((res: ServerResponse<T>) =>
        ['error', 'fail'].includes(res.status) ? throwError(res) : of(res),
      ),
      map((res: ServerResponse<T>) =>
        entityName ? res.data[entityName] : res.data ? res.data : res,
      ),
    );
  },
};
