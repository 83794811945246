import copier from 'copy-to-clipboard';

export const clipboardService = {
  async copy(text: string) {
    return new Promise((resolve) => {
      copier(text);
      resolve(text);
    });
  },
};
