import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  AddDataToUploadPayload,
  uploadFileFailure,
  uploadFileStart,
  uploadFileSuccess,
} from '../../slices';

interface UploadFilePayload {
  data: AddDataToUploadPayload;
  transactionId: string;
}

export const uploadFileEpic = (action$, state$, { Api }) =>
  action$.pipe(
    ofType(uploadFileStart.type),
    switchMap((action: { payload: UploadFilePayload }) =>
      Api.uploadFile(
        action.payload.data.file,
        action.payload.transactionId,
      ).pipe(
        map((link: string) =>
          uploadFileSuccess({ name: action.payload.data.name, link }),
        ),
        catchError((err) => of(uploadFileFailure(err))),
      ),
    ),
  );

export const uploadFile = (
  data: AddDataToUploadPayload,
  transactionId: string,
) => ({
  type: uploadFileStart.type,
  payload: { data, transactionId },
});
