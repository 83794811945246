import * as Sentry from '@sentry/browser';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';

import {
  getPaymentInfoFailure,
  getPaymentInfoStart,
  getPaymentInfoSuccess,
  paymentCurrentStateStart,
  paymentCurrentStateSuccess,
  PaymentTransaction,
} from '../../slices';

export const fetchPaymentInfoEpic = (action$, state$, { Api }) =>
  action$.pipe(
    ofType(getPaymentInfoStart.type),
    switchMap((action: { payload: { transactionId } }) =>
      Api.fetchPaymentInfo(action.payload.transactionId).pipe(
        map((data: PaymentTransaction) => getPaymentInfoSuccess(data)),
        catchError((err) =>
          of(() => {
            Sentry.captureException(new Error('Sentry exception error:'), {
              extra: {
                response: err,
              },
            });
            return getPaymentInfoFailure(err);
          }),
        ),
      ),
    ),
  );

export const fetchCurrentStateRequestEpic = (action$, state$, { Api }) =>
  action$.pipe(
    ofType(paymentCurrentStateStart.type),
    debounceTime(1000),
    switchMap((action: { payload: { transactionId } }) => {
      if (action.payload.transactionId) {
        return Api.fetchPaymentInfo(action.payload.transactionId).pipe(
          map((data: PaymentTransaction) => paymentCurrentStateSuccess(data)),
        );
      }
    }),
  );
