import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { initiateQAToolkit } from '@cashier/cashier-common';

import { environment } from '../../../environments/environment';

const MainPageV1 = React.lazy(() => import('../pages/main/v1/main'));
const MainPageV2 = React.lazy(() => import('../pages/main/v2/main'));
const LoadingPage = React.lazy(() => import('../pages/loading/loading'));
const ConfirmationPage = React.lazy(
  () => import('../pages/confirmation/confirmation'),
);

export const getAppRouter = () => {
  if (!environment.production) {
    initiateQAToolkit();
  }

  return createBrowserRouter([
    {
      path: '/',
      element: (
        <React.Suspense fallback={<>...</>}>
          <MainPageV1 />
        </React.Suspense>
      ),
    },
    {
      path: '/:theme/:type',
      element: (
        <React.Suspense fallback={<>...</>}>
          <MainPageV2 />
        </React.Suspense>
      ),
    },
    {
      path: '/confirm/:id',
      element: (
        <React.Suspense fallback={<>...</>}>
          <ConfirmationPage />
        </React.Suspense>
      ),
    },
    {
      path: '/confirm',
      element: (
        <React.Suspense fallback={<>...</>}>
          <ConfirmationPage />
        </React.Suspense>
      ),
    },
    {
      path: '/success',
      element: (
        <React.Suspense fallback={<>...</>}>
          <ConfirmationPage />
        </React.Suspense>
      ),
    },
    {
      path: '/failure',
      element: (
        <React.Suspense fallback={<>...</>}>
          <ConfirmationPage />
        </React.Suspense>
      ),
    },
    {
      path: '/status/:merchantId/:id/:paymentType',
      element: (
        <React.Suspense fallback={<>...</>}>
          <ConfirmationPage />
        </React.Suspense>
      ),
    },
    {
      path: '/loading',
      element: (
        <React.Suspense fallback={<>...</>}>
          <LoadingPage />
        </React.Suspense>
      ),
    },
  ]);
};
