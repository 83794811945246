import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AccountOption, PaymentMethod } from '../';

export interface IPaymentMethod {
  accounts: Record<string, AccountOption[]>;
  attributes: { [key: string]: string };
  country: string;
  method: PaymentMethod;
  submitUrl: string;
}

export enum TransactionStates {
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
  WAITING_INPUT = 'WAITING_INPUT',
  CANCELLED = 'CANCELLED',
  WAITING_APPROVAL = 'WAITING_APPROVAL',
  PROCESSING = 'PROCESSING',
}

export interface PaymentTransaction {
  transactionId: string;
  state:
    | TransactionStates.SUCCESSFUL
    | TransactionStates.FAILED
    | TransactionStates.WAITING_INPUT
    | TransactionStates.CANCELLED
    | TransactionStates.WAITING_APPROVAL
    | TransactionStates.PROCESSING;
}

export interface PaymentInfoState {
  paymentInfo?: PaymentTransaction;
}

export const PAYMENTS_DATA_FEATURE_KEY = 'paymentInfo';

export const initialPaymentInfoState: PaymentInfoState = {
  paymentInfo: null,
};

export const paymentInfoSlice = createSlice({
  name: PAYMENTS_DATA_FEATURE_KEY,
  initialState: initialPaymentInfoState,
  reducers: {
    getPaymentInfoStart: (
      state,
      action: PayloadAction<{
        merchantId: string;
        userId: string;
        sessionId: string;
        transactionId: string;
      }>,
    ) => undefined,
    getPaymentInfoSuccess: (state, action: PayloadAction<any>) => {
      state.paymentInfo = action.payload;
    },
    getPaymentInfoFailure: (state, action: PayloadAction<any>) => undefined,
    paymentCurrentStateStart: (
      state,
      { payload }: PayloadAction<{ transactionId: string }>,
    ) => undefined,
    paymentCurrentStateSuccess: (state, action: PayloadAction<any>) => {
      state.paymentInfo = action.payload;
    },
    toInitialPaymentInfoState: (state) => {
      state.paymentInfo = initialPaymentInfoState.paymentInfo;
    },
    initiateNewTransaction: () => undefined,
  },
});

export const paymentInfoReducer = paymentInfoSlice.reducer;

export const {
  getPaymentInfoStart,
  getPaymentInfoSuccess,
  getPaymentInfoFailure,
  paymentCurrentStateStart,
  paymentCurrentStateSuccess,
  toInitialPaymentInfoState,
  initiateNewTransaction,
} = paymentInfoSlice.actions;
