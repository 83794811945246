import { createSelector } from '@reduxjs/toolkit';

import { FILE_UPLOADING_FEATURE_KEY, FileUploadingState } from './';

export const getFileUploadingState = (rootState: any): FileUploadingState =>
  rootState[FILE_UPLOADING_FEATURE_KEY];

export const selectDataToUpload = createSelector(
  getFileUploadingState,
  (s) => s.data,
);
