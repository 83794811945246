import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectAmount,
  selectCurrencySymbol,
  selectTaxConfig,
  TaxConfiguration,
} from '../slices';

export const useTaxCalculation = () => {
  const taxConfig = useSelector(selectTaxConfig);
  const currencySymbol = useSelector(selectCurrencySymbol);
  const amount = useSelector(selectAmount);

  const tax: TaxConfiguration | null = React.useMemo(() => {
    /* TODO: rework this hardcode */
    return taxConfig.GHA || null;
  }, [taxConfig]);

  const taxAmount = React.useMemo(() => {
    if (!tax || !amount) {
      return 0;
    }

    /* TODO: there should be separated calculations for each type of taxConfig (for now its only GHA)  */
    const { deductibleStake, taxPercentage } = tax;

    if (Number(amount) < Number(deductibleStake)) {
      return 0;
    }

    const calculatedTax =
      ((Number(amount) - Number(deductibleStake)) * Number(taxPercentage)) /
      100;

    return calculatedTax.toFixed(2);
  }, [amount, taxConfig]);

  return {
    tax,
    amount,
    taxAmount,
    currencySymbol,
  };
};
