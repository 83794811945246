import { generatePath } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Observable } from 'rxjs';

import { ClientSwitcher } from './clients/client-switcher';
import { convertToUrl } from './helpers';
import { IntegrationSource } from './interfaces';
import {
  IPaymentMethod,
  PaymentMethodShort,
  PaymentTransaction,
  ProcessPaymentRequest,
  ProcessPaymentResponse,
  RegisterPaymentRequest,
} from './slices';

export interface PaymentsClientInterface {
  fetchPaymentMethods({
    type,
  }: {
    type: string;
  }): Observable<PaymentMethodShort[]>;
  processPayment(
    request: ProcessPaymentRequest,
  ): Observable<ProcessPaymentResponse>;
  registerPayment(
    request: RegisterPaymentRequest,
  ): Observable<ProcessPaymentResponse>;
  fetchPaymentInfo(transactionId: string): Observable<any>;
  uploadFile(file: File, transactionId: string): Observable<string>;
}

export class PaymentsClient
  extends ClientSwitcher
  implements PaymentsClientInterface
{
  private apiUrlList = {
    get: {
      cashierPaymentMethods: 'api/v1/cashier/payment-methods',
      paymentInfo: 'api/v1/cashier/payment',
    },
    post: {
      cashierMain: 'api/v1/cashier',
      externalProcessTransaction: 'api/v1/cashier/payment/process',
      registerPayment: 'api/v1/cashier/payment/register',
      uploadFile:
        '/api/v1/cashier/payment/:transactionId/document/:documentType',
    },
    put: {
      confirmRequest: 'api/v1/cashier/payment/confirm',
    },
  };

  private static generatePgwAuthorizationToken() {
    const params = new URLSearchParams(window.location.search);

    const pgwMerchantId = params.get('merchantId');
    const accountId = params.get('userId');
    const sessionId = params.get('sessionId');

    if ([pgwMerchantId, accountId, sessionId].some((param) => !param)) {
      Sentry.captureException(
        new Error('####Not enough data for Pgw Authorization Token####:'),
        {
          extra: {
            location: window.location.href,
          },
        },
      );
    }

    return btoa(`${pgwMerchantId}:${accountId}:${sessionId}`);
  }

  fetchPaymentMethods({ type }): Observable<PaymentMethodShort[]> {
    return this.get<any>(
      `${this.apiUrlList.get.cashierPaymentMethods}/${type}`,
      null,
      '',
      {
        'Pgw-Authorization': PaymentsClient.generatePgwAuthorizationToken(),
      },
    );
  }

  processPayment({
    paymentMethodId,
    vendor,
    merchantId,
    paymentType,
    userId,
    sessionId,
    paymentAttributes,
    callbackParameterBag,
    referenceId,
    source,
    accountId,
  }: ProcessPaymentRequest): Observable<ProcessPaymentResponse> {
    const { bonusCode: bonusCodeValue, ...paymentAttributesWithoutBonusCode } =
      paymentAttributes;
    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get('orderId');

    const requestData = {
      [IntegrationSource.Internal]: {
        url: `${this.apiUrlList.post.cashierMain}/${vendor}/process`,
        params: {
          type: paymentType,
          paymentMethodId,
          userId,
          sessionId,
          paymentAttributes: paymentAttributesWithoutBonusCode,
          merchantId,
          referenceId,
          attributes: {
            bonusCode: bonusCodeValue,
            orderId: orderId || '',
          },
          successRedirectUrl: convertToUrl(
            `${window.location.origin}/success`,
            callbackParameterBag,
          ),
          failureRedirectUrl: convertToUrl(
            `${window.location.origin}/failure`,
            callbackParameterBag,
          ),
        },
      },
      [IntegrationSource.External]: {
        url: `${this.apiUrlList.post.externalProcessTransaction}`,
        params: {
          type: paymentType,
          walletId: userId,
          sessionId,
          merchantId,
          referenceId,
          returnUrl: convertToUrl(
            `${window.location.origin}/confirm`,
            callbackParameterBag,
          ),
          payment: {
            accountId,
            methodId: paymentMethodId,
            serviceId: vendor,
            attributes: {
              ...paymentAttributes,
              orderId: orderId || '',
            },
          },
        },
      },
    };

    const { url, params } = requestData[source.toString()];

    return this.post(url, params, '', {
      'Pgw-Authorization': PaymentsClient.generatePgwAuthorizationToken(),
      'Content-Type': 'application/json',
    });
  }

  registerPayment({
    referenceId,
    type,
    walletId,
    sessionId,
    merchantId,
    registration,
  }: RegisterPaymentRequest): Observable<ProcessPaymentResponse> {
    return this.post(
      this.apiUrlList.post.registerPayment,
      {
        referenceId,
        type,
        walletId,
        sessionId,
        merchantId,
        registration,
      },
      '',
      {
        'Pgw-Authorization': PaymentsClient.generatePgwAuthorizationToken(),
        'Content-Type': 'application/json',
      },
    );
  }

  fetchPaymentInfo(transactionId: string): Observable<PaymentTransaction> {
    return this.get<any>(
      `${this.apiUrlList.get.paymentInfo}/${transactionId}`,
      null,
      '',
      {
        'Pgw-Authorization': PaymentsClient.generatePgwAuthorizationToken(),
      },
    );
  }

  fetchCertainPaymentMethod(
    methodType: string,
    paymentMethodId: string,
    bonusCode: string,
  ): Observable<IPaymentMethod> {
    return this.get<any>(
      `${this.apiUrlList.get.cashierPaymentMethods}/${methodType}/${paymentMethodId}`,
      {
        bonusCode,
      },
      '',
      {
        'Pgw-Authorization': PaymentsClient.generatePgwAuthorizationToken(),
      },
    );
  }

  makeConfirmRequest(
    transactionId: string,
    paymentAttributes: any,
  ): Observable<ProcessPaymentResponse> {
    return this.put<any>(
      `${this.apiUrlList.put.confirmRequest}/${transactionId}`,
      { paymentAttributes },
      '',
      {
        'Pgw-Authorization': PaymentsClient.generatePgwAuthorizationToken(),
        'Content-Type': 'application/json',
      },
    );
  }

  makeDirecta24DropdownRequest(url: string, parameters: any): Observable<any> {
    return this.postWithoutApiUrl<any>(url, parameters);
  }

  uploadFile(file: File, transactionId: string): Observable<string> {
    const fileTypeToDocumentType = {
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'application/pdf': 'pdf',
    };

    const documentType = fileTypeToDocumentType[file.type];

    if (!documentType) {
      Sentry.captureException(new Error('####Unsupported file type####:'), {
        extra: {
          fileType: file.type,
        },
      });
    }

    const url = generatePath(this.apiUrlList.post.uploadFile, {
      transactionId,
      documentType,
    });

    return this.post(url, file, '', {
      'Pgw-Authorization': PaymentsClient.generatePgwAuthorizationToken(),
      'Content-Type': file.type,
    });
  }
}
