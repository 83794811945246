export enum CashierInitParams {
  UserId = 'userId',
  SessionId = 'sessionId',
  MerchantId = 'merchantId',
  PaymentType = 'paymentType',
  Currency = 'currency',
  Bonus = 'bonus',
  TermsUrl = 'termsUrl',
  Platform = 'platform',
  Host = 'host',
  Language = 'language',
  AccountId = 'accountId',
  PgwMerchantId = 'pgwMerchantId',
  CustomerBalance = 'customerBalance',
  WebView = 'webView',
  Direction = 'direction',
  Country = 'country',
  OrderId = 'orderId',
  Amount = 'amount',
  window = 'window',
  Theme = 'theme',
}
