import { loadPreprocessorResources } from '../helpers';
import { getCertainPaymentMethodSuccess } from '../slices';

export const dataPreprocessingMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  if (getCertainPaymentMethodSuccess.toString() === action.type) {
    const {
      method: { preprocessor },
    } = action.payload;

    loadPreprocessorResources(preprocessor);
  }

  return result;
};
